import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'

const StyledPhilosophyHomeList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;

  > li {
    list-style: none;
    width: calc((100% - 1px) / 2);
  }

  > li + li {
    margin-left: 1px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: var(--font-size-l2);

    > li {
      width: calc((100% - 2px) / 2);
    }

    > li + li {
      margin-left: 2px;
    }
  }
`

export const PhilosophyHomeList: React.FC = ({ children }) => (
  <StyledPhilosophyHomeList>
    {React.Children.map(children, (child) => (
      <li>{child}</li>
    ))}
  </StyledPhilosophyHomeList>
)
