import React from 'react'

import { ProductMobileHomeList } from './ProductMobileHomeList'
import { ProductTabletList } from './ProductTabletList'
import { useIsTablet } from '../hooks/useIsTablet'

export const ProductHomeList: React.FC = ({ children }) => {
  const isTablet = useIsTablet()
  const HomeList = isTablet ? ProductTabletList : ProductMobileHomeList
  return <HomeList>{children}</HomeList>
}
