import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'

import { LoadingContext } from '../contexts/LoadingContext'

const StyledProductImage = styled.div`
  background: var(--color-figure-0025);
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 150%;
  }

  img {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (prefers-color-scheme: dark) {
    background: var(--color-ground-1000);
  }
`

const Picture = styled.picture<{ loaded: boolean }>`
  img {
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
    transition: opacity 0.5s;
  }
`

export interface ProductImageProps {
  fluidLight: FluidObject
  fluidDark: FluidObject
  alt?: string
  className?: string
  sizes?: string
}

export const ProductImage: React.FC<ProductImageProps> = ({
  fluidLight,
  fluidDark,
  alt = '',
  className,
  sizes,
}) => {
  const [loaded, markLoaded] = useReducer(() => true, false)
  const imgRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if (imgRef.current?.complete) markLoaded()
  }, [])
  const loading = useContext(LoadingContext)

  return (
    <StyledProductImage className={className}>
      <Picture loaded={loaded}>
        <source
          type="image/webp"
          srcSet={fluidDark.srcSetWebp}
          media="(prefers-color-scheme: dark)"
          sizes={sizes}
        />
        <source
          srcSet={fluidDark.srcSet}
          media="(prefers-color-scheme: dark)"
          sizes={sizes}
        />
        <source
          type="image/webp"
          srcSet={fluidLight.srcSetWebp}
          sizes={sizes}
        />
        <source srcSet={fluidLight.srcSet} sizes={sizes} />
        <img
          src={fluidLight.src}
          alt={alt}
          draggable="false"
          ref={imgRef}
          onLoad={markLoaded}
          loading={loading}
        />
      </Picture>
    </StyledProductImage>
  )
}
