import React from 'react'
import { FluidObject } from 'gatsby-image'
import { GatsbyLinkProps } from 'gatsby'
import styled from '@emotion/styled'

import { Bilabel } from './Bilabel'
import { I18nLink } from './I18nLink'
import { OpticalImage } from './OpticalImage'

const StyledPhilosophyItem = styled(I18nLink)`
  color: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-decoration: none;
`

const Image = styled(OpticalImage)`
  margin-top: 0.5em;
  width: 100%;
`

type PhilosophyItemProps = Omit<GatsbyLinkProps<unknown>, 'ref'> & {
  fluidMobile: FluidObject
  fluidTablet: FluidObject
  label: string
  sublabel?: string
  imageSizes?: string
}

export const PhilosophyItem: React.FC<PhilosophyItemProps> = ({
  fluidMobile,
  fluidTablet,
  label,
  sublabel,
  imageSizes,
  ...props
}) => (
  <StyledPhilosophyItem {...props}>
    <Bilabel sublabel={sublabel} label={label} mode="center" />
    <Image
      fluidMobile={fluidMobile}
      fluidTablet={fluidTablet}
      sizes={imageSizes}
    />
  </StyledPhilosophyItem>
)
