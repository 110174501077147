import React from 'react'
import { FluidObject } from 'gatsby-image'
import { GatsbyLinkProps } from 'gatsby'
import styled from '@emotion/styled'

import { Bilabel } from './Bilabel'
import { I18nLink } from './I18nLink'
import { ProductImage } from './ProductImage'

type ProductItemMode = 'tablet' | 'mobile-home' | 'mobile-related'

const StyledProductItem = styled(I18nLink)<{ mode: ProductItemMode }>(
  {
    color: 'inherit',
    textDecoration: 'none',
  },
  ({ mode }) => {
    if (mode === 'tablet') {
      return {
        display: 'block',
        fontSize: 'var(--font-size-l2)',
        position: 'relative',
      }
    }
    if (mode === 'mobile-home') {
      return {
        display: 'grid',
        fontSize: 'var(--font-size-l2)',
        gridTemplateColumns: '4rem 1fr',
      }
    }
    return {
      display: 'block',
    }
  }
)

const Image = styled(ProductImage)<{ mode: ProductItemMode }>(({ mode }) =>
  mode === 'mobile-home'
    ? {
        gridColumn: 2,
      }
    : {}
)

const Label = styled(Bilabel)<{ _mode: ProductItemMode }>(({ _mode }) => {
  if (_mode === 'tablet') {
    return {
      left: '100%',
      paddingLeft: '0.5em',
      position: 'absolute',
      top: 0,
    }
  }
  if (_mode === 'mobile-home') {
    return {
      gridColumn: 1,
      gridRow: 1,
      justifySelf: 'start',
    }
  }
  return {
    marginTop: `${2 / 3}em`,
  }
})

export type ProductItemProps = Omit<GatsbyLinkProps<unknown>, 'ref'> & {
  fluidLight: FluidObject
  fluidDark: FluidObject
  label: string
  sublabel?: string
  mode?: ProductItemMode
  imageSizes?: string
}

export const ProductItem: React.FC<ProductItemProps> = ({
  fluidLight,
  fluidDark,
  label,
  sublabel,
  mode = 'tablet',
  imageSizes,
  ...props
}) => (
  <StyledProductItem mode={mode} {...props}>
    <Image
      fluidLight={fluidLight}
      fluidDark={fluidDark}
      mode={mode}
      sizes={imageSizes}
    />
    <Label
      sublabel={sublabel}
      label={label}
      mode={mode === 'tablet' || mode === 'mobile-home' ? 'vertical' : 'normal'}
      _mode={mode}
    />
  </StyledProductItem>
)
