import React from 'react'
import styled from '@emotion/styled'

import { ProductItemProps } from './ProductItem'

const StyledProductMobileHomeList = styled.ul`
  margin: 0;
  padding: 0 2rem;

  > li {
    list-style: none;
  }

  > li + li {
    margin-top: 2rem;
  }
`

export const ProductMobileHomeList: React.FC = ({ children }) => (
  <StyledProductMobileHomeList>
    {React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null

      const props: ProductItemProps = { ...child.props, mode: 'mobile-home' }
      return <li>{React.cloneElement(child, props)}</li>
    })}
  </StyledProductMobileHomeList>
)
