import React, { useRef, useEffect } from 'react'
import { FluidObject } from 'gatsby-image'
import { useSprings, animated } from 'react-spring'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { OpticalImage } from './OpticalImage'

const StyledHomeHero = styled.div`
  position: relative;
`

const List = styled.ul`
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 150%;
  }

  > li {
    height: 100%;
    list-style: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    &::before {
      padding-top: 50%;
    }
  }
`

const Lead = styled.div`
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 2;
`

interface HomeHeroProps {
  fluids: {
    mobile: FluidObject
    tablet: FluidObject
  }[]
}

const useSlideshowEffect = (length: number) => {
  const scaleTo = 1.1
  const scaleConfig = { mass: 1, tension: 30, friction: 350 }
  const fadeConfig = { mass: 1, tension: 120, friction: 120 }
  const interval = 3000
  const initialInterval = 7000

  const indexRef = useRef(0)

  const [scales, setScales] = useSprings(length, (i) => ({
    config: scaleConfig,
    from: { scale: 1 },
    ...(i === 0 ? { scale: scaleTo } : { scale: 1 }),
  }))
  const [fades, setFades] = useSprings(length, (i) => ({
    immediate: true,
    zIndex: 0,
    ...(i === 0 ? { opacity: 1 } : { opacity: 0 }),
  }))

  useEffect(() => {
    let id: number
    const update = () => {
      const lastIndex = indexRef.current
      const nextIndex = (indexRef.current + 1) % length
      indexRef.current = nextIndex

      setScales((i: number) =>
        i === nextIndex ? { config: scaleConfig, scale: scaleTo } : null
      )
      setFades((i: number) =>
        i === nextIndex
          ? {
              config: fadeConfig,
              immediate: (key: string) => key === 'zIndex',
              opacity: 1,
              zIndex: 1,
              onRest: () => {
                setFades((j: number) => {
                  if (j === nextIndex) {
                    return { immediate: true, zIndex: 0 }
                  }
                  if (j === lastIndex) {
                    return { immediate: true, opacity: 0 }
                  }
                  return null
                })
                setScales((j: number) =>
                  j === lastIndex ? { immediate: true, scale: 1 } : null
                )
                id = window.setTimeout(update, interval)
              },
            }
          : null
      )
    }
    id = window.setTimeout(update, initialInterval)

    return () => window.clearTimeout(id)
  }, [length])

  return scales.map((scale, i) => ({ scale, fade: fades[i] }))
}

export const HomeHero: React.FC<HomeHeroProps> = ({ children, fluids }) => {
  const springs = useSlideshowEffect(fluids.length)

  return (
    <StyledHomeHero>
      <List>
        {springs.map(({ scale: { scale }, fade }, i) => (
          <animated.li
            // https://github.com/react-spring/react-spring/issues/1102
            // @ts-ignore
            style={{
              transform: scale.to((s) => `scale(${s})`),
              ...fade,
            }}
            key={i.toString()}
          >
            <OpticalImage
              fluidMobile={fluids[i].mobile}
              fluidTablet={fluids[i].tablet}
              sizes="100vw"
            />
          </animated.li>
        ))}
      </List>
      {children ? <Lead>{children}</Lead> : null}
    </StyledHomeHero>
  )
}
