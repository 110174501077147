import { SpringValue, useTrail } from 'react-spring'
import { useMemo, useLayoutEffect } from 'react'
import shuffle from 'lodash.shuffle'

interface TrailProps {
  display: SpringValue<string>
  opacity: SpringValue<number>
  y: SpringValue<number>
}

type UseTextEffectType = (
  text: string
) => ({ style: TrailProps; char: string } | null)[]

export const useTextEffect: UseTextEffectType = (text) => {
  const shuffleMap = useMemo(
    () => shuffle([...text].filter((char) => char !== '\n').map((_, i) => i)),
    [text]
  )

  const [trail, set] = useTrail(12, () => ({
    display: 'inline-block',
    opacity: 0,
    y: 7,
    config: { tension: 280, friction: 60 },
  }))

  useLayoutEffect(() => {
    set(() => ({ opacity: 1, y: 0 }))
  }, [])

  const [propList] = [...text].reduce<
    [({ style: TrailProps; char: string } | null)[], number]
  >(
    ([list, charIndex], char) =>
      char === '\n'
        ? [[...list, null], charIndex]
        : [
            [
              ...list,
              { style: trail[shuffleMap[charIndex] % trail.length], char },
            ],
            charIndex + 1,
          ],
    [[], 0]
  )

  return propList
}
