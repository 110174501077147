import React from 'react'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { ProductItemProps } from './ProductItem'

const StyledProductTabletList = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 8rem;

  > li {
    list-style: none;
    width: ${(18 / 70) * 100}%;
  }

  > li + li {
    margin-left: ${(8 / 70) * 100}%;
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0 auto;
    width: 70rem;
  }
`

export const ProductTabletList: React.FC = ({ children }) => (
  <StyledProductTabletList>
    {React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null

      const props: ProductItemProps = { ...child.props, mode: 'tablet' }
      return <li>{React.cloneElement(child, props)}</li>
    })}
  </StyledProductTabletList>
)
